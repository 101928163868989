footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0 30px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

footer svg {
  margin: 0 0.75rem 0 0.75rem;
}

footer svg:hover {
  cursor: pointer;
}
