body {
  margin: 0;
  overflow: hidden;
}

.App {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 85vh auto;
  justify-items: center;
  align-items: center;
  height: 100vh;
  overflow-y: hidden;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
  line-height: 1.25;
  background-color: #fff;
  color: #000;
  transition: background-color 1s;
  transition: color 1s;
}

h1 {
  font-size: 1.5em;
  margin: 1em 0 1em 0;
}

h2 {
  text-align: center;
  white-space: pre-wrap;
  font-size: 8vmin;
  margin: 0;
}
h2 > a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

header {
  background-color: #fff;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row-reverse;
  transition: background-color 1s;
  transition: color 1s;
}

header svg {
  z-index: 10;
  margin: 1rem;
}

header svg:hover {
  cursor: pointer;
}

section {
  width: 70%;
  z-index: 10;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-rows: 0.25fr 0.25fr 20vmax;
}
.dark-mode {
  background-color: #000;
  color: rgba(255, 255, 255, 0.9);
  transition: background-color 1s;
  transition: color 1s;
}
div.circle {
  text-align: center;
  color: #fff;
  padding: 5px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: background-color 1s;
  background-color: #999;
}

div.circle.Home {
  background: linear-gradient(45deg, #000, #000 100%),
    linear-gradient(135deg, #a5cf6f, #a5cf6f),
    linear-gradient(225deg, #9b51e0, #9b51e0),
    linear-gradient(225deg, #f2994a, #f2994a);
  background-size: 50% 50%;
  background-position: 0% 0%, 0% 100%, 100% 0%, 100% 100%;
  background-repeat: no-repeat;
  animation: spin 3000ms linear infinite;
  -webkit-animation: spin 3000ms linear infinite;
  -moz-animation: spin 3000ms linear infinite;
}

div.circle.Art {
  background-color: #000;
}
div.circle.Art.dark-mode {
  background-color: #fff;
  color: #000;
}
div.circle.Sustainability {
  background-color: #a5cf6f;
}
div.circle.Music {
  background-color: #f2994a;
}
div.circle.Digital {
  background-color: #9b51e0;
}

/*==============
/* * Animations
====================================*/
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(359deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/*==============
/* * Media Queries 
====================================*/

@media screen and (max-width: 1024px) {
  section {
    width: 80%;
    margin: 0 1rem 0 1rem;
  }
}

@media screen and (max-width: 1280px) {
  h2 {
    font-size: 6vmin;
  }
  section {
    grid-template-rows: 0.25fr 0.25fr 25vmax;
  }
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 1em;
  }
}

@media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
  @media {
    div.circle.Home {
      background: linear-gradient(45deg, #000, #000 100%),
        linear-gradient(135deg, #a5cf6f, #a5cf6f),
        linear-gradient(225deg, #9b51e0, #9b51e0),
        linear-gradient(225deg, #f2994a, #f2994a);
      background-size: 50% 50%;
      background-position: 0% 0%, 0% 100%, 100% 0%, 100% 100%;
      background-repeat: no-repeat;
    }
  }
}
